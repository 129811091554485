<template>
    <div
        class="flex justify-center rounded-md border-2"
        :class="{
            'border-dashed': !dropping && !modelValue,
            'px-6 pb-6 pt-5': !preview.src,
            'border-primary-500': !errored,
            'border-red-500': errored,
        }"
        @dragover.prevent="dropping = true"
        @dragleave.prevent="dropping = false"
        @drop="dropping = false"
        @drop.prevent="dropImage"
    >
        <div v-if="!modelValue && !preview.src" class="w-full text-center">
            <AsyncIcon name="upload" class="mx-auto h-10 w-10 text-gray-400" />

            <div class="mt-2 flex justify-center text-sm text-gray-600">
                <label
                    :for="id"
                    class="relative cursor-pointer rounded-md bg-white font-medium text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:text-primary-500"
                >
                    <span>{{ trans('Upload a file') }}</span>

                    <input
                        :id="id"
                        :name="name"
                        :accept="extensions.map((ext) => `image/${ext}`).join(',')"
                        type="file"
                        class="sr-only"
                        @change="emit('update:modelValue', $event.target.files[0])"
                    />
                </label>

                <p class="pl-1">
                    {{ trans('or drag and drop') }}
                </p>
            </div>

            <p class="text-xs text-gray-500">
                {{ extensions.join(', ').toUpperCase() }} {{ trans('up to') }}
                {{ human_file_size(maxSize) }}
            </p>
        </div>

        <div v-else class="group aspect-h-6 aspect-w-16 relative block w-full">
            <img :src="preview.src" class="h-full w-full object-cover object-center" alt="" />

            <div
                class="absolute inset-0 flex items-center justify-center bg-black/20 backdrop-invert backdrop-opacity-5 transition-colors group-hover:bg-black/40"
            >
                <div
                    class="hidden -translate-y-10 transform transform transform items-center gap-x-5 text-sm text-white ease-in group-hover:flex group-hover:translate-y-0"
                >
                    <div>
                        <AsyncIcon name="image" class="h-10 w-10" />
                    </div>

                    <div class="flex flex-col">
                        <div>{{ preview.name }}</div>
                        <div class="flex gap-x-2">
                            <div>{{ trans('Size') }}: {{ human_file_size(preview.size) }}</div>
                            <div>
                                {{ trans('Extension') }}:
                                {{ preview.extension.toString().toUpperCase() }}
                            </div>
                        </div>
                    </div>
                </div>

                <AsyncIcon
                    name="xmark"
                    class="absolute right-2 top-2 h-6 w-6 cursor-pointer text-white hover:text-primary-500"
                    aria-hidden="true"
                    @click="emit('update:modelValue', null)"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue'
    import { human_file_size } from '@/scripts/utils'
    import AsyncIcon from '@/views/components/AsyncIcon.vue'

    const props = defineProps({
        modelValue: {
            type: Object,
        },
        id: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        errored: {
            type: Boolean,
            default: false,
        },
        extensions: {
            type: Array,
            default: () => ['png', 'jpg', 'jpeg'],
        },
        maxSize: {
            type: Number,
            default: 1024 * 1024,
        },
    })
    const emit = defineEmits(['update:modelValue'])
    const dropping = ref(false)
    const preview = ref<{
        src: null | string | ArrayBuffer
        name: string
        size: number
        extension: string
    }>({
        src: null,
        name: '',
        size: 0,
        extension: '',
    })
    const dropImage = (event: DragEvent) => {
        if (event.dataTransfer?.files.length ?? 0 > 0) {
            emit('update:modelValue', event.dataTransfer?.files[0])
            dropping.value = false
        }
    }
    watch(
        () => props.modelValue,
        (value) => {
            if (value) {
                const reader = new FileReader()
                reader.onload = (event) => {
                    preview.value.src = event.target?.result ?? null
                    preview.value.name = value.name
                    preview.value.size = value.size
                    preview.value.extension = value.name.split('.').pop() ?? ''
                }
                reader.readAsDataURL(value)
            } else {
                preview.value.src = null
                preview.value.name = ''
                preview.value.size = 0
                preview.value.extension = ''
            }
        },
    )
</script>
