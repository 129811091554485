<template>
    <Dropdown>
        <DropdownButton
            class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 lg:rounded-md lg:p-2 lg:hover:bg-gray-100"
        >
            <div class="relative h-8 w-8">
                <img
                    class="h-8 w-8 rounded-full"
                    :src="user.profile_photo_url"
                    :alt="`${user.name} Avatar`"
                />

                <span
                    v-if="notificationsCount"
                    class="absolute right-0 top-0 -mr-1 -mt-1 flex h-3 w-3"
                >
                    <span
                        class="absolute inline-flex h-full w-full animate-ping rounded-full bg-primary-400 opacity-75"
                    ></span>
                    <span class="relative inline-flex h-3 w-3 rounded-full bg-primary-500"></span>
                </span>
            </div>

            <span class="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                <span class="sr-only">Open user menu for</span>
                {{ user.name }}
            </span>
        </DropdownButton>

        <DropdownItems>
            <MenuItem v-for="(link, i) in links.profile" :key="i" v-slot="{ active, close }">
                <a
                    v-if="link.fullRedirect"
                    :href="route(link.route)"
                    :class="[
                        active ? 'bg-gray-100' : '',
                        'group flex w-full items-center gap-x-3 px-4 py-2 text-sm text-gray-700',
                    ]"
                    @click="close"
                >
                    <AsyncIcon
                        v-if="link.icon"
                        :name="Array.isArray(link.icon) ? link.icon[0] : link.icon"
                        :type="Array.isArray(link.icon) ? link.icon[1] : 'regular'"
                        class="h-5 w-5"
                        aria-hidden="true"
                    />

                    {{ trans(link.label) }}
                </a>

                <Link
                    v-else
                    :href="route(link.route)"
                    :class="[
                        active ? 'bg-gray-100' : '',
                        'group flex w-full items-center gap-x-3 px-4 py-2 text-sm text-gray-700',
                    ]"
                    @click="close"
                >
                    <AsyncIcon
                        v-if="link.icon"
                        :name="Array.isArray(link.icon) ? link.icon[0] : link.icon"
                        :type="Array.isArray(link.icon) ? link.icon[1] : 'regular'"
                        class="h-5 w-5"
                        aria-hidden="true"
                    />

                    {{ trans(link.label) }}

                    <span v-if="link.icon === 'bell'">({{ notificationsCount }})</span>
                </Link>
            </MenuItem>

            <MenuItem v-slot="{ active, close }">
                <Link
                    :href="route('logout')"
                    :class="[
                        active ? 'bg-gray-100' : '',
                        'group flex w-full items-center gap-x-3 px-4 py-2 text-sm text-gray-700',
                    ]"
                    method="post"
                    as="button"
                    @click="close"
                >
                    <AsyncIcon name="arrow-right-from-bracket" class="h-5 w-5" aria-hidden="true" />

                    {{ trans('Logout') }}
                </Link>
            </MenuItem>
        </DropdownItems>
    </Dropdown>
</template>

<script setup lang="ts">
    import { onMounted, onUnmounted, ref } from '@vue/runtime-core'
    import { Link } from '@inertiajs/vue3'
    import { MenuItem } from '@headlessui/vue'
    import { useAuth } from '@/scripts/hooks/useAuth'
    import { useNavigation } from '@/scripts/hooks/useNavigation'
    import { usePusher } from '@/scripts/hooks/usePusher'
    import { useNotifications } from '@/scripts/hooks/useNotifications'

    const { user, notificationsCount: count } = useAuth()
    const { connect, disconnect } = usePusher()
    const { pushNotification } = useNotifications()
    const { links } = useNavigation()
    const channelName = `private-users.${user.value.id}`
    const notificationsCount = ref(count.value)

    onMounted(() => {
        const channel = connect(channelName)

        channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
            notificationsCount.value++

            pushNotification({
                id: data.id,
                title: data.title,
                subtitle: data.subtitle,
                type: data.type,
                action: data.action,
            })
        })
    })

    onUnmounted(() => {
        disconnect(channelName)
    })
</script>
