import { computed } from '@vue/reactivity'
import { usePage } from '@inertiajs/vue3'
import { AuthProp, SharedProps } from '@/scripts/types/page-props'
import { AccountModel, UserModel } from '@/scripts/types/models'

export function useAuth() {
    const props = computed(() => usePage<{ props: SharedProps }>().props)
    const auth = computed(() => props.value.auth as AuthProp)

    const user = computed(() => <UserModel>auth.value?.user.data)
    const account = computed(() => <AccountModel>auth.value?.user.data?.account)
    const classrooms = computed(() => auth.value?.user.data.classrooms)
    const loggedIn = computed(() => !!user.value)
    const isAdmin = computed(() => auth.value?.user?.data.role?.name === 'admin')
    const isEditor = computed(() => auth.value?.user?.data.role?.name === 'editor')
    const isExpert = computed(() => auth.value?.user?.data.role?.name === 'expert')
    const isTeacher = computed(() => auth.value?.user?.data.role?.name === 'teacher')
    const isFamily = computed(() => auth.value?.user?.data.role?.name === 'family')
    const isSecretary = computed(() => auth.value?.user?.data.role?.name === 'secretary')
    const isStudent = computed(() => auth.value?.user?.data.role?.name === 'student')
    const isFreeloader = computed(() => auth.value?.user?.data.role?.name === 'freeloader')
    const permissions = computed(() => auth.value?.permissions)
    const notificationsCount = computed(() => auth.value?.notifications_count)
    return {
        is_impersonating: computed(() => auth.value?.impersonation?.is_impersonating),
        impersonator: computed(() => auth.value?.impersonation?.impersonator),
        user,
        account,
        classrooms,
        loggedIn,
        isAdmin,
        isEditor,
        isExpert,
        isTeacher,
        isFamily,
        isSecretary,
        isStudent,
        isFreeloader,
        permissions,
        notificationsCount,
    }
}
