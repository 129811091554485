import { router } from '@inertiajs/vue3'
import { SharedProps, AppProp } from '@/scripts/types/page-props'
import { usePage } from '@inertiajs/vue3'
import { computed } from '@vue/reactivity'
import { LocaleModel } from '@/scripts/types/models'

export function useLocales() {
    const props = computed(() => usePage<{ props: SharedProps }>().props)
    const app = computed(() => props.value.app as AppProp)
    const locales = computed(() => app.value.locales.all)

    const currentLocale = computed(() => app.value.locales.current)
    const localizedProp = (prop: Record<string, any>) => {
        const result: Record<string, any> = {}

        locales.value?.forEach((locale: LocaleModel) => {
            result[locale.slug] = prop[locale.slug] ?? null
        })

        return result
    }
    const switchLocale = (lang: string) =>
        router.get('', { lang, page: 1 }, { preserveState: true })
    const switchLocaleUrl = (locale: string) => {
        window.location.href = route(
            // @ts-ignore
            route().current().toString().replace(currentLocale.value.slug, locale),
            route().params,
        )
    }
    const displayValue = (value: string | number, by: 'id' | 'slug' = 'slug') =>
        locales.value.find((locale) => locale[by] === value)?.label ?? value

    return { locales, currentLocale, localizedProp, switchLocale, switchLocaleUrl, displayValue }
}
