<template>
    <div class="flex items-stretch gap-x-1">
        <EditorButton
            :active="editor?.isActive('bold')"
            @click="editor.chain().focus().toggleBold().run()"
        >
            <AsyncIcon name="bold" class="h-4 w-4" />
        </EditorButton>

        <EditorButton
            :active="editor?.isActive('italic')"
            @click="editor.chain().focus().toggleItalic().run()"
        >
            <AsyncIcon name="italic" class="h-4 w-4" />
        </EditorButton>

        <EditorButton
            :active="editor?.isActive('underline')"
            @click="editor.chain().focus().toggleUnderline().run()"
        >
            <AsyncIcon name="underline" class="h-4 w-4" />
        </EditorButton>

        <EditorButton
            :active="editor?.isActive('strikethrough')"
            @click="editor.chain().focus().toggleStrike().run()"
        >
            <AsyncIcon name="strikethrough" class="h-4 w-4" />
        </EditorButton>

        <template v-if="buttons.includes('headings')">
            <div class="mx-2 block border-r-2 border-gray-200" />

            <EditorButton
                :active="editor?.isActive('heading', { level: 1 })"
                @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            >
                <AsyncIcon name="h1" class="h-4 w-4" />
            </EditorButton>

            <EditorButton
                :active="editor?.isActive('heading', { level: 2 })"
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            >
                <AsyncIcon name="h2" class="h-4 w-4" />
            </EditorButton>

            <EditorButton
                :active="editor?.isActive('heading', { level: 3 })"
                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
                <AsyncIcon name="h3" class="h-4 w-4" />
            </EditorButton>
        </template>

        <template v-if="buttons.includes('links')">
            <div class="mx-2 block border-r-2 border-gray-200" />

            <EditorButton :active="editor?.isActive('link')" @click="$emit('links:handle')">
                <AsyncIcon name="link" class="h-4 w-4" />
            </EditorButton>
        </template>

        <template v-if="buttons.includes('lists')">
            <div class="mx-2 block border-r-2 border-gray-200" />

            <EditorButton
                :active="editor?.isActive('bulletList')"
                @click="editor.chain().focus().toggleBulletList().run()"
            >
                <AsyncIcon name="list-ul" class="h-4 w-4" />
            </EditorButton>

            <EditorButton
                :active="editor?.isActive('orderedList')"
                @click="editor.chain().focus().toggleOrderedList().run()"
            >
                <AsyncIcon name="list-ol" class="h-4 w-4" />
            </EditorButton>
        </template>

        <template v-if="buttons.includes('images')">
            <div class="mx-2 block border-r-2 border-gray-200" />

            <EditorButton @click="$emit('gallery:show')">
                <AsyncIcon name="image" class="h-4 w-4" />
            </EditorButton>
        </template>

        <EditorButton class="ml-auto" @click="$emit('update:expanded', !expanded)">
            <AsyncIcon :name="expanded ? 'compress' : 'expand'" class="h-4 w-4" />
        </EditorButton>
    </div>
</template>

<script setup lang="ts">
    import { Editor } from '@tiptap/vue-3'
    import { useGallery } from '@/scripts/hooks/useGallery'
    import AsyncIcon from '@/views/components/AsyncIcon.vue'

    defineEmits(['update:expanded', 'gallery:show', 'links:handle'])
    const props = defineProps({
        expanded: {
            type: Boolean,
            default: false,
        },
        editor: {
            type: Object as () => Editor,
            required: false,
            default: undefined,
        },
        buttons: {
            type: Array,
            default: () => [],
        },
    })

    const { emitter } = useGallery()

    emitter.on('gallery:attach', (attachments) => {
        props.editor
            ?.chain()
            .focus()
            .insertContent([
                ...attachments.map((attachment) => ({
                    type: 'image',
                    attrs: {
                        src: attachment.url,
                        alt: attachment.name,
                    },
                })),
                { type: 'paragraph', content: '' },
            ])
            .run()
    })
    emitter.on('gallery:attach-url', (url) => {
        {
            props.editor
                ?.chain()
                .focus()
                .insertContent([
                    { type: 'image', attrs: { src: url } },
                    { type: 'paragraph', content: '' },
                ])
                .run()
        }
    })
</script>
