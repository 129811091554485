<template>
    <button v-if="tag === 'button'" :type="tag === 'button' ? type : null" :class="classList">
        <div :class="{ invisible: loading }">
            <slot />
        </div>
        <div
            class="absolute inset-0 flex items-center justify-center"
            :class="{ invisible: !loading }"
        >
            <Spinner class="h-6 w-6" />
        </div>
    </button>
    <Component
        :is="tag === 'link' ? Link : tag"
        v-else
        :type="tag === 'button' ? type : null"
        :class="classList"
    >
        <div :class="{ invisible: loading }">
            <slot />
        </div>
        <div
            class="absolute inset-0 flex items-center justify-center"
            :class="{ invisible: !loading }"
        >
            <Spinner class="h-6 w-6" />
        </div>
    </Component>
</template>

<script setup lang="ts">
    import { computed } from '@vue/runtime-core'
    import { Link } from '@inertiajs/vue3'
    import Spinner from '@/views/components/Icons/Spinner.vue'

    const props = defineProps({
        type: {
            type: String,
            default: 'button',
        },
        size: {
            type: String,
            default: 'md',
        },
        variant: {
            type: String,
            default: 'primary',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: [String, Object],
            default: 'button',
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        circle: {
            type: Boolean,
            default: false,
        },
    })

    const sizes: { [index: string]: string } = {
        xs: `${props.rounded ? 'px-0.5' : 'px-1'} py-0 text-xs`,
        sm: `${props.rounded ? 'px-1.5' : 'px-2'} py-1.5 text-xs`,
        md: `${props.rounded ? 'px-2' : 'px-4'} py-2 text-sm`,
        lg: `${props.rounded ? 'px-3' : 'px-4 sm:px-8'} py-3 text-base`,
        xl: `${props.rounded ? 'px-3' : 'px-4 sm:px-8'} py-4 text-xl`,
    }

    const variants: { [index: string]: string } = {
        primary: `border ${
            props.transparent
                ? 'border-primary-500 text-primary-500 hover:bg-primary-50 active:bg-primary-50'
                : 'border-transparent text-white bg-primary-600 hover:bg-primary-700 shadow-sm'
        } focus:ring-primary-500`,
        primaryOutline:
            'border border-primary-300 text-primary-500 bg-transparent hover:bg-primary-50 focus:ring-primary-500 shadow-sm',
        secondaryOutline:
            'border border-white text-gray-300 bg-transparent hover:bg-gray-50 hover:text-gray-700 focus:ring-gray-300 shadow-sm',
        secondary:
            'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary-500 shadow-sm',
        dark: 'border border-gray-700 text-white bg-gray-700 focus:ring-primary-500 shadow-sm',
        danger: `border border-transparent ${
            props.transparent
                ? 'text-red-200 hover:bg-red-50 active:bg-red-50'
                : 'text-white bg-red-600 hover:bg-red-700 shadow-sm'
        } focus:ring-red-500`,
        link: 'hover:underline text-primary-300 bg-transparent active:bg-primary-50 focus:ring-primary-500',
    }

    const classList = computed(() => [
        'relative inline-flex items-center justify-center',
        'font-medium',
        props.rounded ? 'rounded-full' : 'rounded-md',
        props.circle ? 'rounded-full w-12 h-12' : 'rounded-md',
        sizes[props.size],
        variants[props.variant],
        'focus:outline-none focus:ring-2 focus:ring-none',
        props.loading || props.disabled ? 'pointer-events-none' : '',
    ])
</script>
