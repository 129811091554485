<template>
    <Component :is="tag === 'link' ? Link : tag" :class="classList">
        <div :class="{ invisible: loading }">
            <slot>
                {{ label }}
            </slot>
        </div>
        <div
            class="absolute inset-0 flex items-center justify-center"
            :class="{ invisible: !loading }"
        >
            <Spinner class="h-4 w-4" />
        </div>
    </Component>
</template>

<script setup lang="ts">
    import { computed } from '@vue/runtime-core'
    import { Link } from '@inertiajs/vue3'

    const props = defineProps({
        label: String,
        tag: {
            type: [String, Object],
            default: 'a',
        },
        variant: {
            type: String,
            default: 'primary',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    })

    const variants: { [index: string]: string } = {
        primary:
            'border border-transparent text-brand-orange hover:bg-primary-50 active:bg-primary-50 focus:ring-primary-500',
        secondary:
            'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500 shadow-sm',
        danger: 'border border-transparent text-red-500 hover:bg-red-50 active:bg-red-50 focus:ring-red-500',
    }

    const classList = computed(() => [
        'relative inline-flex items-center justify-center text-primary-500',
        'font-medium rounded-full p-1.5 text-sm',
        variants[props.variant],
        'focus:outline-none focus:ring-2',
        props.loading ? 'pointer-events-none' : '',
    ])
</script>
