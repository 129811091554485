<template>
    <div
        class="pointer-events-none fixed inset-y-0 right-0 z-50 flex w-full max-w-sm flex-col space-y-4 py-4 pr-4 sm:justify-end"
        role="status"
        aria-live="polite"
    >
        <template v-for="notification in notifications" :key="notification.id">
            <Alert
                :variant="notification.type"
                :title="trans(notification.title)"
                :text="trans(notification.subtitle)"
                :timeout="3000"
                class="pointer-events-auto"
                closable
                @close="removeNotification(notification.id)"
            >
                <template #actions>
                    <template v-if="notification.action">
                        <Link
                            :href="notification.action.href"
                            class="inline-flex h-full cursor-pointer items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-transparent px-4 py-1 text-base font-medium leading-6 text-gray-700 opacity-100 focus:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 dark:text-white dark:hover:text-white dark:focus:ring-offset-gray-900 sm:text-sm"
                        >
                            {{ notification.action.label }}
                        </Link>
                    </template>
                </template>
            </Alert>
        </template>
    </div>
</template>

<script setup lang="ts">
    import { Link } from '@inertiajs/vue3'
    import { computed, onMounted, watch } from 'vue'
    import { useNotifications } from '@/scripts/hooks/useNotifications'
    import { usePage } from '@inertiajs/vue3'
    import { FlashProp, SharedProps } from '@/scripts/types/page-props'
    import { Alert } from '@flavorly/vanilla-components'

    const { notifications, pushNotification, removeNotification, parseNotifications } =
        useNotifications()

    const props = computed(() => usePage<{ props: SharedProps }>().props)

    onMounted(() =>
        parseNotifications(props.value.flash as FlashProp).forEach((notification) =>
            pushNotification(notification),
        ),
    )
    watch(
        () => props.value.flash as FlashProp,
        (newValue: FlashProp) =>
            parseNotifications(newValue).forEach((notification) => pushNotification(notification)),
    )
</script>
