import { usePage } from '@inertiajs/vue3'
import { computed } from '@vue/runtime-core'
import { SharedProps } from '@/scripts/types/page-props'

export function useNavigation() {
    const props = computed(() => usePage<{ props: SharedProps }>().props)

    const links = computed(() => props.value.links)

    return {
        links,
    }
}
