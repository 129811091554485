<template>
    <Component :is="tag" :class="{ 'cursor-pointer': !copied }" @click.prevent="copyToClipboard">
        <AsyncIcon v-if="!copied" name="clipboard" class="h-5 w-5" />

        <AsyncIcon
            v-else
            name="clipboard-check"
            class="pointer-events-none h-5 w-5 text-green-500"
        />
    </Component>
</template>

<script setup lang="ts">
    import { ref } from '@vue/runtime-core'
    import { useClipboard } from '@/scripts/hooks/useClipboard'
    import AsyncIcon from '@/views/components/AsyncIcon.vue'

    const props = defineProps({
        text: String,
        tag: {
            type: [String, Object],
            default: 'span',
        },
    })
    const copied = ref(false)
    const { copy } = useClipboard()
    const copyToClipboard = () => {
        copy(props.text).then(() => {
            copied.value = true

            setTimeout(() => {
                copied.value = false
            }, 3000)
        })
    }
</script>
