<template>
    <div class="rounded-md border-l-4 p-4" :class="containerClassList">
        <div class="flex">
            <div class="flex-shrink-0">
                <AsyncIcon :name="icon" :class="iconClassList" class="h-5 w-5" aria-hidden="true" />
            </div>

            <div class="ml-3 space-y-2">
                <h3 v-if="title" class="text-sm font-medium" :class="titleClassList">
                    {{ title }}
                </h3>

                <div class="text-sm" :class="messageClassList">
                    <slot v-if="message" name="message">
                        {{ trans(message) }}
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import AsyncIcon from '@/views/components/AsyncIcon.vue'

    const props = defineProps({
        title: String,
        message: {
            type: String,
            required: false,
        },
        variant: {
            type: String,
            required: true,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
    })
    const containerVariants: { [index: string]: string } = {
        success: 'bg-green-50 border-green-400',
        info: 'bg-blue-50 border-blue-400',
        warning: 'bg-orange-50 border-orange-400',
        danger: 'bg-red-50 border-red-400',
    }
    const titleVariants: { [index: string]: string } = {
        success: 'text-green-800',
        info: 'text-blue-800',
        warning: 'text-orange-800',
        danger: 'text-red-800',
    }
    const messageVariants: { [index: string]: string } = {
        success: 'text-green-700',
        info: 'text-blue-700',
        warning: 'text-orange-700',
        danger: 'text-red-700',
    }
    const iconVariants: { [index: string]: string } = {
        success: 'text-green-400',
        info: 'text-blue-400',
        warning: 'text-orange-400',
        danger: 'text-red-400',
    }
    const icons: { [index: string]: string } = {
        success: 'circle-check',
        info: 'circle-info',
        warning: 'triangle-exclamation',
        danger: 'circle-xmark',
    }
    const containerClassList = computed(() => [
        props.shadow ? 'shadow-md' : '',
        containerVariants[props.variant],
    ])
    const titleClassList = computed(() => titleVariants[props.variant])
    const messageClassList = computed(() => messageVariants[props.variant])
    const iconClassList = computed(() => iconVariants[props.variant])
    const icon = computed(() => icons[props.variant])
</script>
