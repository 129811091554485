import { createApp, h, DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { globalComponentsPlugin } from '@/scripts/vue/global-components-plugin'
import { i18nVue } from 'laravel-vue-i18n'
import { modal } from 'momentum-modal'
import '@/css/app.css'
import { router } from '@inertiajs/vue3'
import axios from 'axios'
import { VanillaComponents } from '@flavorly/vanilla-components'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import VanillaComponentsConfig from '@/static/vanilla-components-config'
import { PageView } from '@/scripts/types/models'
import { useAuth } from '@/scripts/hooks/useAuth'
import * as Sentry from '@sentry/vue'

router.on('navigate', (event) => {
    const { user, is_impersonating } = useAuth()
    if (is_impersonating) return
    if (!user.value?.id || ['admin', 'super'].includes(user.value?.role.name)) return

    const data = event.detail.page.props

    const pageView: PageView = {
        name: 'page_view',
        program_id: data?.program?.data.id,
        challenge_id: data?.challenge?.data.id,
        resource_id: data?.resource?.data?.id,
        data: {
            url: event.detail.page.url,
        },
    }
    axios.post(route('app.events.dispatch'), pageView).then((result) => console.log(result))
})

createInertiaApp({
  
    resolve: async (name: string): Promise<DefineComponent> => {
        const pages = import.meta.glob('../views/pages/**/*.vue')
        const page = await pages[`../views/pages/${name}.vue`]() as { default: DefineComponent }
        return page.default
    },
    title: (title) => `${title} — ${import.meta.env.VITE_APP_NAME}`,
    setup: ({ el, App, props, plugin }) => {
        const vueApp = createApp({ render: () => h(App, props) })

        Sentry.init({
            app: vueApp,
            dsn: 'https://a2ceb8bc1c044c07920ddb0dab266e94@o830124.ingest.sentry.io/5812474',
            environment: import.meta.env.APP_ENV,
            tracesSampleRate: 1.0,
        })

        vueApp
            .use(globalComponentsPlugin)
            .use(i18nVue, {
                resolve: async (lang: string) => {
                    const languages = import.meta.glob('../../lang/*.json')
                    return await languages[`../../lang/${lang.replace(/[_-]CA/, '')}.json`]()
                },
            })
            .use(modal, {
                resolve: async (name: string) =>  {
                    return resolvePageComponent(`../views/pages/${name}.vue`,
                        import.meta.glob('../views/pages/**/*.vue'),
                    )
                }
            })
            .use(VanillaComponents, VanillaComponentsConfig)
            .use(plugin)
            .mount(el)
    },
})

// send missing images and css to sentry
document.body.addEventListener(
    'error',
    (event: ErrorEvent) => {
        if (!event.target) return
        if (!event.target.tagName) return

        if (event.target.tagName === 'IMG') {
            Sentry.captureMessage(
                `Failed to load image: ${(event.target as HTMLImageElement).src}`,
                'warning',
            )
        } else if (event.target.tagName === 'LINK') {
            Sentry.captureMessage(`Failed to load css: ${event.target.href}`, 'warning')
        }
    },
    true,
)
