<template>
    <div class="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
        <SimplePaginator :links="links" class="sm:hidden" />

        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-700">
                    Showing
                    {{ ' ' }}
                    <span class="font-medium">{{ meta?.from || 0 }}</span>
                    {{ ' ' }}
                    to
                    {{ ' ' }}
                    <span class="font-medium">{{ meta?.to || 0 }}</span>
                    {{ ' ' }}
                    of
                    {{ ' ' }}
                    <span class="font-medium">{{ meta?.total }}</span>
                    {{ ' ' }}
                    results
                </p>
            </div>

            <div v-if="meta?.links.length > 3">
                <nav
                    class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                >
                    <template v-for="(link, i) in meta?.links" :key="i">
                        <div
                            v-if="link.url === null"
                            class="relative inline-flex select-none items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
                            :class="{
                                'rounded-l-md': i === 0,
                                'rounded-r-md': i === meta?.links.length - 1,
                            }"
                        >
                            <template v-if="i === 0">
                                <span class="sr-only">Previous</span>
                                <AsyncIcon name="angle-left" class="h-5 w-5" aria-hidden="true" />
                            </template>

                            <template v-if="i === meta?.links.length - 1">
                                <span class="sr-only">Next</span>
                                <AsyncIcon name="angle-right" class="h-5 w-5" aria-hidden="true" />
                            </template>

                            <template v-if="i > 0 && i < meta?.links.length - 1">
                                {{ link.label }}
                            </template>
                        </div>

                        <Button
                            v-else
                            class="relative inline-flex items-center border px-4 py-2 text-sm font-medium text-gray-500"
                            :class="{
                                'pointer-events-none z-10 border-primary-500 bg-primary-50 text-primary-600':
                                    link.active,
                                'border-gray-300 bg-white !text-gray-500 hover:bg-gray-50':
                                    !link.active,
                                'rounded-l-md': i === 0,
                                'rounded-r-md': i === meta?.links.length - 1,
                            }"
                            @click="paginationClick(link.url)"
                        >
                            <template v-if="i === 0">
                                <span class="sr-only">Previous</span>
                                <AsyncIcon name="angle-left" class="h-5 w-5" aria-hidden="true" />
                            </template>

                            <template v-if="i === meta?.links.length - 1">
                                <span class="sr-only">Next</span>
                                <AsyncIcon name="angle-right" class="h-5 w-5" aria-hidden="true" />
                            </template>

                            <template v-if="i > 0 && i < meta?.links.length - 1">
                                {{ link.label }}
                            </template>
                        </Button>
                    </template>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { router } from '@inertiajs/vue3'
    import Button from '../Form/Button.vue'

    defineProps({ meta: Object, links: Object })
    const paginationClick = (value: any) => {
        const pagination = value.split('=')
        const url_params = new URLSearchParams(window.location.search)
        url_params.set('page', pagination[1])
        router.visit(window.location.pathname + '?' + url_params.toString())
    }
</script>
