import { computed } from '@vue/runtime-core'
import { usePage } from '@inertiajs/vue3'
import { SharedProps } from '@/scripts/types/page-props'

export function useDatatable() {
    const props = computed(() => usePage<{ props: SharedProps }>().props)
    const datatable = computed(() => (props.value.request as { datatable: { per_page: string } }).datatable)

    return {
        per_page: datatable.value?.per_page || '15',
    }
}
