import { Ziggy } from '@/scripts/routes'
import { default as getRoute, RouteParams, RouteParamsWithQueryOverload, Router } from 'ziggy-js'
import { useLocales } from '@/scripts/hooks/useLocales'

// @ts-ignore
export function route(name?: undefined, params?: RouteParamsWithQueryOverload | RouteParams, absolute?: boolean): Router;
export function route(name: string, params?: RouteParamsWithQueryOverload | RouteParams, absolute?: boolean): string
export function route(name: string, params?: RouteParamsWithQueryOverload | RouteParams, absolute?: boolean) {
    const { currentLocale } = useLocales()

    if (name && Object.prototype.hasOwnProperty.call(Ziggy.routes, `${currentLocale.value.slug}.${name}`)) {
        return getRoute(`${currentLocale.value.slug}.${name}`, params, absolute, Ziggy)
    }

    return getRoute(name, params, absolute, Ziggy)
}
